@import 'scss/mixins';
@import 'scss/variables';

.Wrapper {
  display: inline-block;
  position: relative;
  flex-shrink: 0;
  vertical-align: text-bottom;
  border-radius: 50%;
  border: 2px solid transparent;
  box-sizing: content-box;

  &.withInitials {
    text-align: center;
  }
}

.Bg {
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;
  transition: opacity .3s ease-out;

  &.transparent {
    opacity: 0.5;
  }

  &.blurred {
    filter: blur(3px);
  
    @include ieonly {
      opacity: .5;
    }
  }

  &.bg-null {
    background: $gray-light-3;
  }

  @each $ix, $color in $user-palette {
    &.bg-#{$ix} {
      background: $color;
      color: white;
    }
  }
}

.Initials {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: .5em;
  white-space: nowrap;
}

.Image {
  position: relative;
  z-index: 2;
  object-fit: cover;
}

.Placeholder {
  width: 100%;
  height: 100%;
  transform: translate(-1px, 5px);
}

@each $name, $size in $avatar-sizes {
  .size-#{$name} {
    width: $size;
    height: $size;
    font-size: $size;
  }
}
