.DateTimeRangeInput {
  display: flex;
  align-items: stretch;
  border: 1px solid #c7c7c7;
  border-radius: 2px;
  transition: border-color 150ms ease; }
  .DateTimeRangeInput:hover {
    border-color: #8c8c8c; }
  .DateTimeRangeInput--active, .DateTimeRangeInput:focus {
    outline: none;
    border-color: #0a86cd;
    box-shadow: inset 0 5px 7px #f0f0f0; }
  .DateTimeRangeInput--error {
    border-color: #ed1717; }
  .DateTimeRangeInput--marked {
    background-color: #fbffb7; }
  .DateTimeRangeInput--rounded {
    border-radius: 15px; }

.DateTimeRangeInput__Input {
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: 0;
  text-align: center; }
  .DateTimeRangeInput__Input:focus {
    box-shadow: none; }

.DateTimeRangeInput__Icon {
  border-left: 1px solid #c7c7c7;
  background: #e2e2e2;
  color: #868686;
  line-height: 2rem; }

.DateTimeRangeInput__Separator {
  line-height: 2rem; }
