.StageIndicator {
  margin: 0 5px;
  font-weight: 600; }
  .StageIndicator:before {
    display: inline-block;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-right: 5px;
    vertical-align: baseline;
    transition: background .15s linear; }
  .StageIndicator--ACTIVE:before {
    background: #ffd849; }
  .StageIndicator--DONE:before {
    background: #82ffd1; }
  .StageIndicator--IMPLEMENTING:before {
    background: #34f065; }
  .StageIndicator--SAVE_FOR_LATER:before {
    background: #a835ff; }
  .StageIndicator--STOPPED:before {
    background: #cc0000; }
  .StageIndicator--VALIDATION:before {
    background: #bdff55; }
  .StageIndicator--FINAL_ASSESSMENT:before {
    background: #007ea7; }
  .StageIndicator--COACHING:before {
    background: #054a29; }
