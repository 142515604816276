.Dropdown__content {
  position: absolute;
  top: calc(100% + 8px);
  border: 1px solid #c7c7c7;
  background: white;
  border-radius: 5px;
  padding: 5px 0;
  z-index: 10700;
  min-width: 100%; }
  .Dropdown__content:before, .Dropdown__content:after {
    display: none;
    content: '';
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
    border-style: solid; }
  .Dropdown__content:before {
    z-index: 3;
    transform: translateY(2px);
    border-width: 0 7px 10px 7px;
    border-color: transparent transparent white transparent; }
  .Dropdown__content:after {
    z-index: 2;
    border-width: 0 7.5px 10px 7.5px;
    border-color: transparent transparent #c7c7c7 transparent; }
  .Dropdown__content--bubble-left {
    top: calc(100% + 15px); }
    .Dropdown__content--bubble-left:before, .Dropdown__content--bubble-left:after {
      display: block;
      left: 25px; }
    .Dropdown__content--bubble-left:before {
      transform: translate(0.5px, 2px); }
  .Dropdown__content--bubble-center {
    top: calc(100% + 15px); }
    .Dropdown__content--bubble-center:before, .Dropdown__content--bubble-center:after {
      display: block;
      left: 50%; }
    .Dropdown__content--bubble-center:before {
      transform: translate(-50%, 2px); }
    .Dropdown__content--bubble-center:after {
      transform: translateX(-50%); }
  .Dropdown__content--bubble-right {
    top: calc(100% + 15px); }
    .Dropdown__content--bubble-right:before, .Dropdown__content--bubble-right:after {
      display: block;
      right: 25px; }
    .Dropdown__content--bubble-right:before {
      transform: translate(-0.25px, 2px); }
