.Screen {
  padding: 0;
}

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.Spinner {
  width: 200px;
  height: 200px;
  margin: 0 auto 1em;
}

.CenterBox {
  position: relative;
  width: 400px;
  max-width: 100%;
}

.Content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
  white-space: pre-line;
}