@import 'scss/variables';
@import 'scss/mixins';

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Item {
  display: inline-block;
  padding: .35em .75em .3em;
  border: 1px solid transparent;
  border-radius: 0;
  text-align: center;
  text-shadow: 0 1px 1px white;
  font-weight: normal;
  cursor: pointer;

  &:focus, &:focus-within {
    outline: 2px solid #66a1db;
  }

  &.checked {
    color: #fff;
    box-shadow: inset 0 3px 7px 0 rgba(28, 76, 128, 0.33);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    border-color: #457eb8;
    background: #66a1db;
  }

  &.disabled {
    color: #dddddd;
    border: 1px solid #ececec;
    cursor: not-allowed;
  }

  input {
    position: absolute;
    clip: rect(0,0,0,0);
  }
}