.ConfirmDialog {
  border-radius: 5px; }
  .ConfirmDialog__Title {
    font-weight: normal; }
  .ConfirmDialog__Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem; }
    .ConfirmDialog__Footer Button {
      width: calc(50% - 10px / 2);
      height: auto;
      min-height: 70px;
      font-weight: lighter; }
      .ConfirmDialog__Footer Button span {
        white-space: normal; }
