.TimeInput {
  display: inline-block;
  border: 1px solid #ddd;
  margin-bottom: .5rem; }

.TimeInput__Input {
  border: 0;
  display: inline;
  width: 2.75rem;
  text-align: center;
  -moz-appearance: textfield; }
  .TimeInput__Input::-webkit-inner-spin-button, .TimeInput__Input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
