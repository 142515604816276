@import 'scss/mixins';
@import 'scss/variables';

$spacing-v: 1em;
$spacing-h: .5em;

.MobileCta {
  display: flex;
  justify-content: center;
  padding: 0 $spacing-h $spacing-v;

  > * {
    width: 100%;
    max-width: 330px;
  }
}

.alignWithMenu {
  transform: translateY(-7px);
}