.Table {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative; }

.Table__Body--fixed {
  max-width: 50%; }

.Table__Body--scroll {
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white 15px) 0 0/50px 100%, radial-gradient(to right, rgba(0, 0, 0, 0.2), transparent 70%) 0 0/20px 100%, linear-gradient(to right, rgba(255, 255, 255, 0), white 15px) right/50px 100%, radial-gradient(to left, rgba(0, 0, 0, 0.2), transparent 70%) right/20px 100%;
  background-repeat: no-repeat;
  background-attachment: local, scroll, local, scroll;
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1; }

.Table__HeaderRow {
  display: flex;
  z-index: 2;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  height: 42px;
  border-bottom: 1px solid #e1e1e1;
  background: #fff;
  min-width: 100%; }

.Table__HeaderCol {
  padding-right: 10px;
  min-width: 0;
  font-size: 0.9rem;
  white-space: nowrap; }
  .Table__HeaderCol > * {
    vertical-align: middle; }

.Table__HeaderCol--center {
  text-align: center; }

.Table__HeaderCol--right {
  text-align: right; }

.Table__Row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  position: relative;
  z-index: 1;
  height: 50px;
  min-width: 0;
  border-top: 1px solid #f0f0f0; }
  .Table__Row:first-child {
    border: none; }

.Table__Col {
  line-height: 1;
  padding-right: 10px;
  min-width: 0;
  font-size: 0.9rem; }

.Table__Col--center {
  text-align: center; }

.Table__Col--right {
  text-align: right; }

.Table__TruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }
