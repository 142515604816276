// Most probably based on https://css-tricks.com/recreating-the-twitter-heart-animation/

$bubble-d: 4.5rem; // bubble diameter
$bubble-r: .5 * $bubble-d; // bubble-radius
$particle-d: .375rem;
$particle-r: .5 * $particle-d;

@mixin particles($k) {
  $shadow-list: ();
  $n-groups: 7;
  $group-base-angle: 360deg / $n-groups;
  $group-distr-r: (1 + $k * .25) * $bubble-r;
  $n-particles: 2;
  $particle-base-angle: 360deg / $n-particles;
  $particle-off-angle: 60deg; // offset angle from radius
  $spread-r: -$k * 1.1 * $particle-r;
  
  @for $i from 0 to $n-groups {
      $group-curr-angle: $i * $group-base-angle - 90deg;
      $xg: $group-distr-r * cos($group-curr-angle);
      $yg: $group-distr-r * sin($group-curr-angle);
      
      @for $j from 0 to $n-particles {
          $particle-curr-angle: $group-curr-angle + 
              $particle-off-angle + $j * $particle-base-angle;
          $xs: $xg + $particle-d * cos($particle-curr-angle);
          $ys: $yg + $particle-d * sin($particle-curr-angle);
          
          $shadow-list: $shadow-list, $xs $ys 0 $spread-r 
              hsl(($i + $j)*$group-base-angle, 100%, 75%);
      }
  }
  
  box-shadow: $shadow-list;
}

@keyframes HeartAnimation {
  0%,
  17.5% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes HeartBubbleAnimation {
  15% {
    border-color: #cc8ef5;
    border-width: $bubble-r;
    transform: scale(1);
  }
  30%, 100% {
    border-color: #cc8ef5;
    border-width: 0;
    transform: scale(1);
  }
}

@keyframes HeartParticlesAnimation {
  0%, 20% { opacity: 0; }
  25% {
    opacity: 1;
    @include particles(0);
  }
}