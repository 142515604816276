.fade-slide-up-enter-active, .fade-slide-up-appear-active, .fade-slide-up-exit-active {
  transition-property: opacity, transform, max-height, height;
  transition-duration: 0.25s;
  transition-timing-function: ease-out; }

.fade-slide-up-enter, .fade-slide-up-appear {
  left: 0;
  right: 0;
  opacity: 0.01;
  transform: translateY(100px);
  overflow-y: hidden !important; }
  .fade-slide-up-enter-active, .fade-slide-up-appear-active {
    z-index: 2;
    opacity: 1;
    transform: translateY(0);
    overflow-x: hidden; }

.fade-slide-up-exit {
  top: 0;
  opacity: 1;
  transform: translateY(0); }
  .fade-slide-up-exit-active {
    opacity: 0;
    transform: translateY(-100px);
    overflow-x: hidden; }
