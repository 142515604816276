.Col {
  width: 100%;
  min-width: 0; }

.Col--auto-12 {
  width: auto; }

.Col--1-12 {
  width: 8.33333%; }

.Col--2-12 {
  width: 16.66667%; }

.Col--3-12 {
  width: 25%; }

.Col--4-12 {
  width: 33.33333%; }

.Col--5-12 {
  width: 41.66667%; }

.Col--6-12 {
  width: 50%; }

.Col--7-12 {
  width: 58.33333%; }

.Col--8-12 {
  width: 66.66667%; }

.Col--9-12 {
  width: 75%; }

.Col--10-12 {
  width: 83.33333%; }

.Col--11-12 {
  width: 91.66667%; }

.Col--12-12 {
  width: 100%; }

@media (min-width: 320px) {
  .Col--1-12\@xs {
    width: 8.33333%; }
  .Col--2-12\@xs {
    width: 16.66667%; }
  .Col--3-12\@xs {
    width: 25%; }
  .Col--4-12\@xs {
    width: 33.33333%; }
  .Col--5-12\@xs {
    width: 41.66667%; }
  .Col--6-12\@xs {
    width: 50%; }
  .Col--7-12\@xs {
    width: 58.33333%; }
  .Col--8-12\@xs {
    width: 66.66667%; }
  .Col--9-12\@xs {
    width: 75%; }
  .Col--10-12\@xs {
    width: 83.33333%; }
  .Col--11-12\@xs {
    width: 91.66667%; }
  .Col--12-12\@xs {
    width: 100%; } }

@media (min-width: 576px) {
  .Col--1-12\@sm {
    width: 8.33333%; }
  .Col--2-12\@sm {
    width: 16.66667%; }
  .Col--3-12\@sm {
    width: 25%; }
  .Col--4-12\@sm {
    width: 33.33333%; }
  .Col--5-12\@sm {
    width: 41.66667%; }
  .Col--6-12\@sm {
    width: 50%; }
  .Col--7-12\@sm {
    width: 58.33333%; }
  .Col--8-12\@sm {
    width: 66.66667%; }
  .Col--9-12\@sm {
    width: 75%; }
  .Col--10-12\@sm {
    width: 83.33333%; }
  .Col--11-12\@sm {
    width: 91.66667%; }
  .Col--12-12\@sm {
    width: 100%; } }

@media (min-width: 768px) {
  .Col--1-12\@md {
    width: 8.33333%; }
  .Col--2-12\@md {
    width: 16.66667%; }
  .Col--3-12\@md {
    width: 25%; }
  .Col--4-12\@md {
    width: 33.33333%; }
  .Col--5-12\@md {
    width: 41.66667%; }
  .Col--6-12\@md {
    width: 50%; }
  .Col--7-12\@md {
    width: 58.33333%; }
  .Col--8-12\@md {
    width: 66.66667%; }
  .Col--9-12\@md {
    width: 75%; }
  .Col--10-12\@md {
    width: 83.33333%; }
  .Col--11-12\@md {
    width: 91.66667%; }
  .Col--12-12\@md {
    width: 100%; } }

@media (min-width: 992px) {
  .Col--1-12\@lg {
    width: 8.33333%; }
  .Col--2-12\@lg {
    width: 16.66667%; }
  .Col--3-12\@lg {
    width: 25%; }
  .Col--4-12\@lg {
    width: 33.33333%; }
  .Col--5-12\@lg {
    width: 41.66667%; }
  .Col--6-12\@lg {
    width: 50%; }
  .Col--7-12\@lg {
    width: 58.33333%; }
  .Col--8-12\@lg {
    width: 66.66667%; }
  .Col--9-12\@lg {
    width: 75%; }
  .Col--10-12\@lg {
    width: 83.33333%; }
  .Col--11-12\@lg {
    width: 91.66667%; }
  .Col--12-12\@lg {
    width: 100%; } }

@media (min-width: 1200px) {
  .Col--1-12\@xl {
    width: 8.33333%; }
  .Col--2-12\@xl {
    width: 16.66667%; }
  .Col--3-12\@xl {
    width: 25%; }
  .Col--4-12\@xl {
    width: 33.33333%; }
  .Col--5-12\@xl {
    width: 41.66667%; }
  .Col--6-12\@xl {
    width: 50%; }
  .Col--7-12\@xl {
    width: 58.33333%; }
  .Col--8-12\@xl {
    width: 66.66667%; }
  .Col--9-12\@xl {
    width: 75%; }
  .Col--10-12\@xl {
    width: 83.33333%; }
  .Col--11-12\@xl {
    width: 91.66667%; }
  .Col--12-12\@xl {
    width: 100%; } }

@media (min-width: 320px) {
  .Col--auto-12\@xs {
    width: auto; } }

@media (min-width: 576px) {
  .Col--auto-12\@sm {
    width: auto; } }

@media (min-width: 768px) {
  .Col--auto-12\@md {
    width: auto; } }

@media (min-width: 992px) {
  .Col--auto-12\@lg {
    width: auto; } }

@media (min-width: 1200px) {
  .Col--auto-12\@xl {
    width: auto; } }

.Col--offset-0 {
  margin-left: 0%; }

@media (min-width: 320px) {
  .Col--offset-0\@xs {
    margin-left: 0%; } }

.Col--offset-1 {
  margin-left: 8.33333%; }

@media (min-width: 320px) {
  .Col--offset-1\@xs {
    margin-left: 8.33333%; } }

.Col--offset-2 {
  margin-left: 16.66667%; }

@media (min-width: 320px) {
  .Col--offset-2\@xs {
    margin-left: 16.66667%; } }

.Col--offset-3 {
  margin-left: 25%; }

@media (min-width: 320px) {
  .Col--offset-3\@xs {
    margin-left: 25%; } }

.Col--offset-4 {
  margin-left: 33.33333%; }

@media (min-width: 320px) {
  .Col--offset-4\@xs {
    margin-left: 33.33333%; } }

.Col--offset-5 {
  margin-left: 41.66667%; }

@media (min-width: 320px) {
  .Col--offset-5\@xs {
    margin-left: 41.66667%; } }

.Col--offset-6 {
  margin-left: 50%; }

@media (min-width: 320px) {
  .Col--offset-6\@xs {
    margin-left: 50%; } }

.Col--offset-7 {
  margin-left: 58.33333%; }

@media (min-width: 320px) {
  .Col--offset-7\@xs {
    margin-left: 58.33333%; } }

.Col--offset-8 {
  margin-left: 66.66667%; }

@media (min-width: 320px) {
  .Col--offset-8\@xs {
    margin-left: 66.66667%; } }

.Col--offset-9 {
  margin-left: 75%; }

@media (min-width: 320px) {
  .Col--offset-9\@xs {
    margin-left: 75%; } }

.Col--offset-10 {
  margin-left: 83.33333%; }

@media (min-width: 320px) {
  .Col--offset-10\@xs {
    margin-left: 83.33333%; } }

.Col--offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 320px) {
  .Col--offset-11\@xs {
    margin-left: 91.66667%; } }

.Col--offset-12 {
  margin-left: 100%; }

@media (min-width: 320px) {
  .Col--offset-12\@xs {
    margin-left: 100%; } }

.Col--offset-0 {
  margin-left: 0%; }

@media (min-width: 576px) {
  .Col--offset-0\@sm {
    margin-left: 0%; } }

.Col--offset-1 {
  margin-left: 8.33333%; }

@media (min-width: 576px) {
  .Col--offset-1\@sm {
    margin-left: 8.33333%; } }

.Col--offset-2 {
  margin-left: 16.66667%; }

@media (min-width: 576px) {
  .Col--offset-2\@sm {
    margin-left: 16.66667%; } }

.Col--offset-3 {
  margin-left: 25%; }

@media (min-width: 576px) {
  .Col--offset-3\@sm {
    margin-left: 25%; } }

.Col--offset-4 {
  margin-left: 33.33333%; }

@media (min-width: 576px) {
  .Col--offset-4\@sm {
    margin-left: 33.33333%; } }

.Col--offset-5 {
  margin-left: 41.66667%; }

@media (min-width: 576px) {
  .Col--offset-5\@sm {
    margin-left: 41.66667%; } }

.Col--offset-6 {
  margin-left: 50%; }

@media (min-width: 576px) {
  .Col--offset-6\@sm {
    margin-left: 50%; } }

.Col--offset-7 {
  margin-left: 58.33333%; }

@media (min-width: 576px) {
  .Col--offset-7\@sm {
    margin-left: 58.33333%; } }

.Col--offset-8 {
  margin-left: 66.66667%; }

@media (min-width: 576px) {
  .Col--offset-8\@sm {
    margin-left: 66.66667%; } }

.Col--offset-9 {
  margin-left: 75%; }

@media (min-width: 576px) {
  .Col--offset-9\@sm {
    margin-left: 75%; } }

.Col--offset-10 {
  margin-left: 83.33333%; }

@media (min-width: 576px) {
  .Col--offset-10\@sm {
    margin-left: 83.33333%; } }

.Col--offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .Col--offset-11\@sm {
    margin-left: 91.66667%; } }

.Col--offset-12 {
  margin-left: 100%; }

@media (min-width: 576px) {
  .Col--offset-12\@sm {
    margin-left: 100%; } }

.Col--offset-0 {
  margin-left: 0%; }

@media (min-width: 768px) {
  .Col--offset-0\@md {
    margin-left: 0%; } }

.Col--offset-1 {
  margin-left: 8.33333%; }

@media (min-width: 768px) {
  .Col--offset-1\@md {
    margin-left: 8.33333%; } }

.Col--offset-2 {
  margin-left: 16.66667%; }

@media (min-width: 768px) {
  .Col--offset-2\@md {
    margin-left: 16.66667%; } }

.Col--offset-3 {
  margin-left: 25%; }

@media (min-width: 768px) {
  .Col--offset-3\@md {
    margin-left: 25%; } }

.Col--offset-4 {
  margin-left: 33.33333%; }

@media (min-width: 768px) {
  .Col--offset-4\@md {
    margin-left: 33.33333%; } }

.Col--offset-5 {
  margin-left: 41.66667%; }

@media (min-width: 768px) {
  .Col--offset-5\@md {
    margin-left: 41.66667%; } }

.Col--offset-6 {
  margin-left: 50%; }

@media (min-width: 768px) {
  .Col--offset-6\@md {
    margin-left: 50%; } }

.Col--offset-7 {
  margin-left: 58.33333%; }

@media (min-width: 768px) {
  .Col--offset-7\@md {
    margin-left: 58.33333%; } }

.Col--offset-8 {
  margin-left: 66.66667%; }

@media (min-width: 768px) {
  .Col--offset-8\@md {
    margin-left: 66.66667%; } }

.Col--offset-9 {
  margin-left: 75%; }

@media (min-width: 768px) {
  .Col--offset-9\@md {
    margin-left: 75%; } }

.Col--offset-10 {
  margin-left: 83.33333%; }

@media (min-width: 768px) {
  .Col--offset-10\@md {
    margin-left: 83.33333%; } }

.Col--offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 768px) {
  .Col--offset-11\@md {
    margin-left: 91.66667%; } }

.Col--offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .Col--offset-12\@md {
    margin-left: 100%; } }

.Col--offset-0 {
  margin-left: 0%; }

@media (min-width: 992px) {
  .Col--offset-0\@lg {
    margin-left: 0%; } }

.Col--offset-1 {
  margin-left: 8.33333%; }

@media (min-width: 992px) {
  .Col--offset-1\@lg {
    margin-left: 8.33333%; } }

.Col--offset-2 {
  margin-left: 16.66667%; }

@media (min-width: 992px) {
  .Col--offset-2\@lg {
    margin-left: 16.66667%; } }

.Col--offset-3 {
  margin-left: 25%; }

@media (min-width: 992px) {
  .Col--offset-3\@lg {
    margin-left: 25%; } }

.Col--offset-4 {
  margin-left: 33.33333%; }

@media (min-width: 992px) {
  .Col--offset-4\@lg {
    margin-left: 33.33333%; } }

.Col--offset-5 {
  margin-left: 41.66667%; }

@media (min-width: 992px) {
  .Col--offset-5\@lg {
    margin-left: 41.66667%; } }

.Col--offset-6 {
  margin-left: 50%; }

@media (min-width: 992px) {
  .Col--offset-6\@lg {
    margin-left: 50%; } }

.Col--offset-7 {
  margin-left: 58.33333%; }

@media (min-width: 992px) {
  .Col--offset-7\@lg {
    margin-left: 58.33333%; } }

.Col--offset-8 {
  margin-left: 66.66667%; }

@media (min-width: 992px) {
  .Col--offset-8\@lg {
    margin-left: 66.66667%; } }

.Col--offset-9 {
  margin-left: 75%; }

@media (min-width: 992px) {
  .Col--offset-9\@lg {
    margin-left: 75%; } }

.Col--offset-10 {
  margin-left: 83.33333%; }

@media (min-width: 992px) {
  .Col--offset-10\@lg {
    margin-left: 83.33333%; } }

.Col--offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 992px) {
  .Col--offset-11\@lg {
    margin-left: 91.66667%; } }

.Col--offset-12 {
  margin-left: 100%; }

@media (min-width: 992px) {
  .Col--offset-12\@lg {
    margin-left: 100%; } }

.Col--offset-0 {
  margin-left: 0%; }

@media (min-width: 1200px) {
  .Col--offset-0\@xl {
    margin-left: 0%; } }

.Col--offset-1 {
  margin-left: 8.33333%; }

@media (min-width: 1200px) {
  .Col--offset-1\@xl {
    margin-left: 8.33333%; } }

.Col--offset-2 {
  margin-left: 16.66667%; }

@media (min-width: 1200px) {
  .Col--offset-2\@xl {
    margin-left: 16.66667%; } }

.Col--offset-3 {
  margin-left: 25%; }

@media (min-width: 1200px) {
  .Col--offset-3\@xl {
    margin-left: 25%; } }

.Col--offset-4 {
  margin-left: 33.33333%; }

@media (min-width: 1200px) {
  .Col--offset-4\@xl {
    margin-left: 33.33333%; } }

.Col--offset-5 {
  margin-left: 41.66667%; }

@media (min-width: 1200px) {
  .Col--offset-5\@xl {
    margin-left: 41.66667%; } }

.Col--offset-6 {
  margin-left: 50%; }

@media (min-width: 1200px) {
  .Col--offset-6\@xl {
    margin-left: 50%; } }

.Col--offset-7 {
  margin-left: 58.33333%; }

@media (min-width: 1200px) {
  .Col--offset-7\@xl {
    margin-left: 58.33333%; } }

.Col--offset-8 {
  margin-left: 66.66667%; }

@media (min-width: 1200px) {
  .Col--offset-8\@xl {
    margin-left: 66.66667%; } }

.Col--offset-9 {
  margin-left: 75%; }

@media (min-width: 1200px) {
  .Col--offset-9\@xl {
    margin-left: 75%; } }

.Col--offset-10 {
  margin-left: 83.33333%; }

@media (min-width: 1200px) {
  .Col--offset-10\@xl {
    margin-left: 83.33333%; } }

.Col--offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 1200px) {
  .Col--offset-11\@xl {
    margin-left: 91.66667%; } }

.Col--offset-12 {
  margin-left: 100%; }

@media (min-width: 1200px) {
  .Col--offset-12\@xl {
    margin-left: 100%; } }

.Col--gutter-0 {
  padding: 0; }

.Col--gutter-x-0 {
  padding-left: 0;
  padding-right: 0; }

.Col--gutter-y-0 {
  padding-top: 0;
  padding-bottom: 0; }

.Col--gutter-1 {
  padding: 5px; }

.Col--gutter-x-1 {
  padding-left: 5px;
  padding-right: 5px; }

.Col--gutter-y-1 {
  padding-top: 5px;
  padding-bottom: 5px; }

.Col--gutter-2 {
  padding: 10px; }

.Col--gutter-x-2 {
  padding-left: 10px;
  padding-right: 10px; }

.Col--gutter-y-2 {
  padding-top: 10px;
  padding-bottom: 10px; }

.Col--gutter-3 {
  padding: 20px; }

.Col--gutter-x-3 {
  padding-left: 20px;
  padding-right: 20px; }

.Col--gutter-y-3 {
  padding-top: 20px;
  padding-bottom: 20px; }

.Col--gutter-4 {
  padding: 50px; }

.Col--gutter-x-4 {
  padding-left: 50px;
  padding-right: 50px; }

.Col--gutter-y-4 {
  padding-top: 50px;
  padding-bottom: 50px; }

@media (min-width: 320px) {
  .Col--gutter-0\@xs {
    padding: 0; }
  .Col--gutter-x-0\@xs {
    padding-left: 0;
    padding-right: 0; }
  .Col--gutter-y-0\@xs {
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 576px) {
  .Col--gutter-0\@sm {
    padding: 0; }
  .Col--gutter-x-0\@sm {
    padding-left: 0;
    padding-right: 0; }
  .Col--gutter-y-0\@sm {
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 768px) {
  .Col--gutter-0\@md {
    padding: 0; }
  .Col--gutter-x-0\@md {
    padding-left: 0;
    padding-right: 0; }
  .Col--gutter-y-0\@md {
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 992px) {
  .Col--gutter-0\@lg {
    padding: 0; }
  .Col--gutter-x-0\@lg {
    padding-left: 0;
    padding-right: 0; }
  .Col--gutter-y-0\@lg {
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 1200px) {
  .Col--gutter-0\@xl {
    padding: 0; }
  .Col--gutter-x-0\@xl {
    padding-left: 0;
    padding-right: 0; }
  .Col--gutter-y-0\@xl {
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 320px) {
  .Col--gutter-1\@xs {
    padding: 5px; }
  .Col--gutter-x-1\@xs {
    padding-left: 5px;
    padding-right: 5px; }
  .Col--gutter-y-1\@xs {
    padding-top: 5px;
    padding-bottom: 5px; } }

@media (min-width: 576px) {
  .Col--gutter-1\@sm {
    padding: 5px; }
  .Col--gutter-x-1\@sm {
    padding-left: 5px;
    padding-right: 5px; }
  .Col--gutter-y-1\@sm {
    padding-top: 5px;
    padding-bottom: 5px; } }

@media (min-width: 768px) {
  .Col--gutter-1\@md {
    padding: 5px; }
  .Col--gutter-x-1\@md {
    padding-left: 5px;
    padding-right: 5px; }
  .Col--gutter-y-1\@md {
    padding-top: 5px;
    padding-bottom: 5px; } }

@media (min-width: 992px) {
  .Col--gutter-1\@lg {
    padding: 5px; }
  .Col--gutter-x-1\@lg {
    padding-left: 5px;
    padding-right: 5px; }
  .Col--gutter-y-1\@lg {
    padding-top: 5px;
    padding-bottom: 5px; } }

@media (min-width: 1200px) {
  .Col--gutter-1\@xl {
    padding: 5px; }
  .Col--gutter-x-1\@xl {
    padding-left: 5px;
    padding-right: 5px; }
  .Col--gutter-y-1\@xl {
    padding-top: 5px;
    padding-bottom: 5px; } }

@media (min-width: 320px) {
  .Col--gutter-2\@xs {
    padding: 10px; }
  .Col--gutter-x-2\@xs {
    padding-left: 10px;
    padding-right: 10px; }
  .Col--gutter-y-2\@xs {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media (min-width: 576px) {
  .Col--gutter-2\@sm {
    padding: 10px; }
  .Col--gutter-x-2\@sm {
    padding-left: 10px;
    padding-right: 10px; }
  .Col--gutter-y-2\@sm {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media (min-width: 768px) {
  .Col--gutter-2\@md {
    padding: 10px; }
  .Col--gutter-x-2\@md {
    padding-left: 10px;
    padding-right: 10px; }
  .Col--gutter-y-2\@md {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media (min-width: 992px) {
  .Col--gutter-2\@lg {
    padding: 10px; }
  .Col--gutter-x-2\@lg {
    padding-left: 10px;
    padding-right: 10px; }
  .Col--gutter-y-2\@lg {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media (min-width: 1200px) {
  .Col--gutter-2\@xl {
    padding: 10px; }
  .Col--gutter-x-2\@xl {
    padding-left: 10px;
    padding-right: 10px; }
  .Col--gutter-y-2\@xl {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media (min-width: 320px) {
  .Col--gutter-3\@xs {
    padding: 20px; }
  .Col--gutter-x-3\@xs {
    padding-left: 20px;
    padding-right: 20px; }
  .Col--gutter-y-3\@xs {
    padding-top: 20px;
    padding-bottom: 20px; } }

@media (min-width: 576px) {
  .Col--gutter-3\@sm {
    padding: 20px; }
  .Col--gutter-x-3\@sm {
    padding-left: 20px;
    padding-right: 20px; }
  .Col--gutter-y-3\@sm {
    padding-top: 20px;
    padding-bottom: 20px; } }

@media (min-width: 768px) {
  .Col--gutter-3\@md {
    padding: 20px; }
  .Col--gutter-x-3\@md {
    padding-left: 20px;
    padding-right: 20px; }
  .Col--gutter-y-3\@md {
    padding-top: 20px;
    padding-bottom: 20px; } }

@media (min-width: 992px) {
  .Col--gutter-3\@lg {
    padding: 20px; }
  .Col--gutter-x-3\@lg {
    padding-left: 20px;
    padding-right: 20px; }
  .Col--gutter-y-3\@lg {
    padding-top: 20px;
    padding-bottom: 20px; } }

@media (min-width: 1200px) {
  .Col--gutter-3\@xl {
    padding: 20px; }
  .Col--gutter-x-3\@xl {
    padding-left: 20px;
    padding-right: 20px; }
  .Col--gutter-y-3\@xl {
    padding-top: 20px;
    padding-bottom: 20px; } }

@media (min-width: 320px) {
  .Col--gutter-4\@xs {
    padding: 50px; }
  .Col--gutter-x-4\@xs {
    padding-left: 50px;
    padding-right: 50px; }
  .Col--gutter-y-4\@xs {
    padding-top: 50px;
    padding-bottom: 50px; } }

@media (min-width: 576px) {
  .Col--gutter-4\@sm {
    padding: 50px; }
  .Col--gutter-x-4\@sm {
    padding-left: 50px;
    padding-right: 50px; }
  .Col--gutter-y-4\@sm {
    padding-top: 50px;
    padding-bottom: 50px; } }

@media (min-width: 768px) {
  .Col--gutter-4\@md {
    padding: 50px; }
  .Col--gutter-x-4\@md {
    padding-left: 50px;
    padding-right: 50px; }
  .Col--gutter-y-4\@md {
    padding-top: 50px;
    padding-bottom: 50px; } }

@media (min-width: 992px) {
  .Col--gutter-4\@lg {
    padding: 50px; }
  .Col--gutter-x-4\@lg {
    padding-left: 50px;
    padding-right: 50px; }
  .Col--gutter-y-4\@lg {
    padding-top: 50px;
    padding-bottom: 50px; } }

@media (min-width: 1200px) {
  .Col--gutter-4\@xl {
    padding: 50px; }
  .Col--gutter-x-4\@xl {
    padding-left: 50px;
    padding-right: 50px; }
  .Col--gutter-y-4\@xl {
    padding-top: 50px;
    padding-bottom: 50px; } }

.Col--stretch {
  align-self: stretch;
  flex: 1 0 0;
  min-width: 0; }

.Col--first {
  order: -1; }
  @media (min-width: 320px) {
    .Col--first\@xs {
      order: -1; } }
  @media (min-width: 576px) {
    .Col--first\@sm {
      order: -1; } }
  @media (min-width: 768px) {
    .Col--first\@md {
      order: -1; } }
  @media (min-width: 992px) {
    .Col--first\@lg {
      order: -1; } }
  @media (min-width: 1200px) {
    .Col--first\@xl {
      order: -1; } }

.Col--last {
  order: 1; }
  @media (min-width: 320px) {
    .Col--last\@xs {
      order: 1; } }
  @media (min-width: 576px) {
    .Col--last\@sm {
      order: 1; } }
  @media (min-width: 768px) {
    .Col--last\@md {
      order: 1; } }
  @media (min-width: 992px) {
    .Col--last\@lg {
      order: 1; } }
  @media (min-width: 1200px) {
    .Col--last\@xl {
      order: 1; } }

.Col--unordered {
  order: 0; }
  @media (min-width: 320px) {
    .Col--unordered\@xs {
      order: 0; } }
  @media (min-width: 576px) {
    .Col--unordered\@sm {
      order: 0; } }
  @media (min-width: 768px) {
    .Col--unordered\@md {
      order: 0; } }
  @media (min-width: 992px) {
    .Col--unordered\@lg {
      order: 0; } }
  @media (min-width: 1200px) {
    .Col--unordered\@xl {
      order: 0; } }
