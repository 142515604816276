.profile-card {
  font-size: 0.9rem;
  color: #f0f0f0;
  min-height: 130px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px; }

.profile-card__name {
  padding-left: 15px; }

.profile-card__title {
  display: flex;
  align-items: center;
  font-size: 1rem;
  height: 50px; }

.profile-card__footer {
  display: inline-block;
  border: 1px solid #6b6b6b;
  padding: 3px 5px;
  text-transform: uppercase; }
