.TutorialButton {
  color: #8e8e8e;
  display: inline-flex;
  flex-direction: column;
  text-align: center; }
  .TutorialButton .TutorialButton__Icon {
    width: 33px;
    height: 24px;
    margin: auto auto 3px;
    display: flex;
    border: 1px solid #cbcbcb;
    border-radius: 2px; }
    .TutorialButton .TutorialButton__Icon .IconWrapper {
      display: flex;
      margin: auto; }
  .TutorialButton .Tutorial__Text {
    font-size: 0.7rem;
    display: flex; }
