.modal-wrapper {
  position: fixed;
  z-index: 10400;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 1rem; }
  @media (max-width: 767px) {
    .modal-wrapper {
      padding: 0; } }
  .modal-wrapper .modal {
    opacity: 0;
    transform: scale(0.5);
    transition: transform 0.3s ease, opacity 0.3s ease; }
  .modal-wrapper.appear-active .modal, .modal-wrapper.appear-done .modal {
    opacity: 1;
    transform: scale(1); }
  .modal-wrapper.exit-active .modal {
    opacity: 0;
    transform: scale(0); }

.modal {
  position: relative;
  min-height: 0;
  background-color: transparent;
  backface-visibility: hidden;
  z-index: 10400; }
  .modal, .modal--full {
    width: 100%; }
  @media (min-width: 768px) {
    .modal--small {
      width: 360px; }
    .modal--medium {
      width: 460px; } }
  @media (min-width: 992px) {
    .modal--large {
      width: 900px; } }
  .modal__footer {
    display: flex;
    justify-content: center;
    align-items: center; }
    .modal__footer--confirm {
      justify-content: space-between; }

.modal--rounded {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.modal-backdrop {
  transition: opacity 0.3s ease; }
  .modal-backdrop.appear {
    opacity: 0; }
  .modal-backdrop.appear-active, .modal-backdrop.appear-done {
    opacity: 1; }
  .modal-backdrop.exit-active {
    opacity: 0; }
