.PrimaryNavigationItem {
  display: flex;
  position: relative;
  color: #424242;
  font-weight: bold;
  align-items: center;
  margin-right: 2vw; }
  .PrimaryNavigationItem--push {
    margin-left: auto;
    margin-right: 0; }
  .PrimaryNavigationItem--center {
    justify-content: center;
    margin-left: 0;
    margin-right: 0; }

.PrimaryNavigationItem__Icon {
  vertical-align: middle;
  font-size: 1.3rem; }
  @media (min-width: 768px) {
    .PrimaryNavigationItem__Icon {
      font-size: 1em; } }

.PrimaryNavigationItem__Link {
  cursor: pointer;
  color: #424242;
  padding: 1rem .8rem;
  display: flex;
  flex-direction: column;
  text-align: center; }
  .PrimaryNavigationItem__Link:hover {
    color: #2c6bb8; }
  @media (min-width: 768px) {
    .PrimaryNavigationItem__Link {
      display: block; } }

.PrimaryNavigationItem__Link--secondary {
  color: #868686;
  font-weight: normal;
  font-size: 0.9rem; }

.PrimaryNavigationItem__Link--active {
  color: #2c6bb8; }
  .PrimaryNavigationItem__Link--active:after {
    position: absolute;
    content: '';
    border-bottom: 4px solid #68a9f9;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0; }
