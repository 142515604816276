$gray-light-1: #c7c7c7;
$spacing-v: 1em;
$spacing-h: .5em;

.PageHeader, .Section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PageHeader {
  position: relative;
  background-color: white;
  padding: $spacing-v 0;
  
  // below-desktop
  @media (max-width: 991px) {
    padding: .5em 0;
  }

  // below-tablet
  @media (max-width: 767px) {
    font-size: 14px;
  }

  &.last-child {
    margin-bottom: 1em;
    border-bottom: 1px solid $gray-light-1;
  }
}

.Section, .Item {
  margin-left: $spacing-h;
  margin-right: $spacing-h;
}