.Popover {
  background: #fff;
  z-index: 10600;
  border-radius: 4px;
  border: 1px solid #a1a1a1;
  text-align: left;
  white-space: normal;
  -webkit-font-smoothing: subpixel-antialiased; }
  .Popover[data-placement="bottom"] {
    margin-top: 15px; }
  .Popover[data-placement="top"] {
    margin-bottom: 15px; }
  .Popover[data-placement="left"] {
    margin-right: 15px; }
  .Popover[data-placement="right"] {
    margin-left: 15px; }

.Popover__Body {
  padding-top: 10px;
  max-height: 450px;
  overflow-y: auto; }

.Popover__Footer {
  text-align: center;
  line-height: 22px;
  overflow: hidden; }

.Popover__Arrow {
  position: absolute;
  border: 1px solid #c7c7c7; }
  .Popover__Arrow, .Popover__Arrow:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }
  .Popover__Arrow:after {
    border-color: transparent;
    border-width: 10px; }
  .Popover__Arrow {
    border-color: transparent;
    border-width: 11px; }
  .Popover[data-placement="bottom"] .Popover__Arrow {
    bottom: 100%;
    border-bottom-color: #c7c7c7; }
  .Popover[data-placement="bottom"] .Popover__Arrow:after {
    border-bottom-color: #ffffff;
    margin-top: -8px;
    margin-left: -10px; }
  .Popover[data-placement="top"] .Popover__Arrow {
    top: 100%;
    border-top-color: #c7c7c7; }
  .Popover[data-placement="top"] .Popover__Arrow:after {
    border-top-color: #ffffff;
    margin-top: -12px;
    margin-left: -10px; }
  .Popover[data-placement="left"] .Popover__Arrow {
    left: 100%;
    border-left-color: #c7c7c7; }
  .Popover[data-placement="left"] .Popover__Arrow:after {
    border-left-color: #ffffff;
    margin-left: -12px;
    margin-top: -10px; }
  .Popover[data-placement="right"] .Popover__Arrow {
    right: 100%;
    border-right-color: #c7c7c7; }
  .Popover[data-placement="right"] .Popover__Arrow:after {
    border-right-color: #ffffff;
    margin-left: -8px;
    margin-top: -10px; }
