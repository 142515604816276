.SliderInput {
  margin-bottom: 20px; }
  .SliderInput.disabled {
    pointer-events: none; }

.rc-slider-track {
  transition: width 0.1s ease-out;
  background: linear-gradient(to right, #3EDE91, #377dd4); }

.rc-slider-handle {
  width: 20px;
  height: 20px;
  margin-top: -9px;
  transition-property: left, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
  border-color: #377dd4;
  touch-action: none; }
  .rc-slider-handle:focus {
    border-color: #2b70c7;
    box-shadow: 0 0 0 5px #377dd4; }
  .rc-slider-handle:hover, .rc-slider-handle:active {
    border-color: #2b70c7; }
  .rc-slider-handle:active {
    box-shadow: 0 0 5px #2b70c7; }
  .rc-slider-handle-click-focused:focus {
    border-color: #377dd4; }

.rc-slider-dot {
  bottom: -6px;
  margin-left: -8px;
  width: 16px;
  height: 16px; }

.rc-slider-tooltip {
  transition: left 0.1s ease-out; }

.rc-slider-mark {
  top: 22px; }

.rc-slider-mark-text {
  user-select: none; }

.rc-slider-vertical {
  min-height: 240px; }
  .rc-slider-vertical .rc-slider-handle {
    margin-bottom: -9px;
    margin-left: -8px; }
  .rc-slider-vertical .rc-slider-mark {
    left: 22px; }

.slanted .rc-slider-mark-text {
  white-space: nowrap;
  transform-origin: right bottom;
  transform: translateX(-100%) rotate(-45deg) !important; }

.error .rc-slider-rail,
.error .rc-slider-track {
  background: linear-gradient(to right, #f24343, #f57373); }
