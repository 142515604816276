.SecondaryNavigationItem {
  display: inline;
  padding-right: .5rem; }
  @media (min-width: 768px) {
    .SecondaryNavigationItem {
      display: block;
      padding-right: 0; } }

.SecondaryNavigationItem__Link {
  color: #6b6b6b; }
  .SecondaryNavigationItem__Link:focus {
    color: #6b6b6b; }

.SecondaryNavigationItem__Link--active {
  font-weight: bold;
  color: #424242; }
