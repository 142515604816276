.alert-transition--enter {
  opacity: .01;
  transition: opacity .5s ease-in; }

.alert-transition--enter-active {
  opacity: 1; }

.alert-transition--exit {
  opacity: 1;
  transition: opacity .5s ease-in; }

.alert-transition--exit-active {
  opacity: .01; }

.alert {
  border-radius: 4px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 0 1px 1px rgba(10, 10, 11, 0.125);
  margin-bottom: 2px;
  max-height: 400px;
  overflow: hidden;
  padding: .5rem 1rem;
  position: relative;
  text-align: center;
  font-size: 0.9rem; }
  .alert--success {
    background: #e8f7ed;
    border-color: #30b661;
    color: #207941; }
  .alert--info {
    background: rgba(88, 97, 110, 0.9);
    border-color: transparent;
    color: #fff; }
  .alert--warning {
    background: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b; }
  .alert--danger {
    background: #e24667;
    border-color: #e24667;
    color: #fff; }

.AlertContainer {
  min-width: 100px;
  max-width: 400px;
  margin: auto;
  position: fixed;
  z-index: 10900; }

.AlertContainer--top {
  top: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%; }

.AlertContainer--right {
  top: 1rem;
  right: 50%;
  transform: translate(50%, 0); }
  @media (min-width: 576px) {
    .AlertContainer--right {
      top: 150px;
      right: calc((100% - 540px) / 2);
      transform: none; } }
  @media (min-width: 768px) {
    .AlertContainer--right {
      top: 150px;
      right: calc((100% - 720px) / 2);
      transform: none; } }
  @media (min-width: 992px) {
    .AlertContainer--right {
      top: 150px;
      right: calc((100% - 960px) / 2);
      transform: none; } }
  @media (min-width: 1200px) {
    .AlertContainer--right {
      top: 150px;
      right: calc((100% - 1170px) / 2);
      transform: none; } }
