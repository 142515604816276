@import 'scss/variables';
@import 'scss/functions';
@import 'scss/mixins';
@import './animation';

.Heart {
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin: 0;
  z-index: 0;

  &:after,
  &:before {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    content: '';
  }

  &::before {
    transform: scale(0);
  }

  &.animate {
    &:before,
    &:after {
      animation-duration: 1s;
      animation-timing-function: cubic-bezier(.21, .61, .35, 1);
    }
  }

  &.checked {
    &::before {
      margin: -$bubble-r;
      width: $bubble-d;
      height: $bubble-d;
      will-change: transform, border-color, border-width;
      box-sizing: border-box;
      border: solid $bubble-r $heart-icon-active-color;
      animation-name: HeartBubbleAnimation;
    }
    
    &:after {
      margin: -$particle-r;
      width: $particle-d;
      height: $particle-d;
      will-change: opacity, box-shadow;
      animation-name: HeartParticlesAnimation;
      @include particles(1);
    }
  }

  &.disabled {
    opacity: .5;
    cursor: default;
  }
}

.IconWrapper {
  padding: 0.2em;
}

.Icon {
  margin: 0;
  color: $gray-4;

  &:hover,
  &:focus {
    color: $gray-2;
  }

  .disabled & {
    &:hover,
    &:focus {
      color: $gray-4;
    }
  }

  .checked & {
    color: $heart-icon-active-color;

    &:hover,
    &:focus {
      color: $heart-icon-active-color;
    }
  }

  .checked.animate & {
    animation: HeartAnimation 1s cubic-bezier(.17, .89, .32, 1.49);
  }
}

.Checkbox {
  position: absolute;
  clip: rect(0,0,0,0);
}

.Badge {
  color: $gray-4;
}