@import 'scss/variables';

.Item {
  font-size: $font-size-sm;
  padding: .3rem 0;
}

.Icon {
  color: $list-marker-color;
  font-size: 1.5rem;
  vertical-align: middle;
}