.HelpIcon__Trigger {
  font-size: .625rem;
  color: #9a9a9a;
  text-transform: uppercase;
  cursor: pointer; }
  .HelpIcon__Trigger__Icon {
    vertical-align: top;
    margin-right: 5px; }

.HelpIcon__Popover {
  max-width: 670px;
  padding: 35px; }
  .HelpIcon__Popover .Popover__Body {
    padding: 0; }

.HelpIcon__Tip__Title {
  margin-top: 0;
  font-weight: 600; }

.HelpIcon__Tip__Description {
  font-size: .875rem;
  color: #7097a5; }

.HelpIcon__Tip__Button {
  min-width: 150px; }
