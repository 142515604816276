.ChallengeSearchPopover {
  width: 500px;
  height: 450px;
  max-width: 90vw;
  max-height: 90vh;
  border: 0;
  box-shadow: -5px 1px 17px 0 rgba(128,128,128,0.5);

  > * {
    height: 100%;
    max-height: unset;
    overflow: initial;
  }
}