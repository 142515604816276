.Navbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: .5rem 0;
  margin: 0 auto; }
  @media (min-width: 576px) {
    .Navbar {
      padding: 1rem 0; } }

@media (min-width: 576px) {
  .Navbar--default, .Navbar--login {
    margin-left: calc(50% - 270px); } }

@media (min-width: 768px) {
  .Navbar--default, .Navbar--login {
    margin-left: calc(50% - 360px); } }

@media (min-width: 992px) {
  .Navbar--default, .Navbar--login {
    margin-left: calc(50% - 480px); } }

@media (min-width: 1200px) {
  .Navbar--default, .Navbar--login {
    margin-left: calc(50% - 585px); } }

@media (min-width: 576px) {
  .Navbar--login {
    margin-left: 0; } }

.Navbar--feed {
  width: 100%; }
  @media (min-width: 576px) {
    .Navbar--feed {
      margin-left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 768px) {
    .Navbar--feed {
      margin-left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 992px) {
    .Navbar--feed {
      margin-left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 1200px) {
    .Navbar--feed {
      margin-left: 50%;
      transform: translateX(-50%); } }

.Navbar--bottom {
  position: fixed;
  z-index: 10200;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #c7c7c7;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
  background: #fff; }

.Navbar__Center {
  flex: auto 1 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 576px) {
    .Navbar--default .Navbar__Center, .Navbar--login .Navbar__Center {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .Navbar--default .Navbar__Center, .Navbar--login .Navbar__Center {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .Navbar--default .Navbar__Center, .Navbar--login .Navbar__Center {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .Navbar--default .Navbar__Center, .Navbar--login .Navbar__Center {
      max-width: 1170px; } }
  @media (min-width: 576px) {
    .Navbar--default .Navbar__Center\@sm, .Navbar--login .Navbar__Center\@sm {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .Navbar--default .Navbar__Center\@md, .Navbar--login .Navbar__Center\@md {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .Navbar--default .Navbar__Center\@lg, .Navbar--login .Navbar__Center\@lg {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .Navbar--default .Navbar__Center\@xl, .Navbar--login .Navbar__Center\@xl {
      max-width: 1170px; } }
  @media (min-width: 1504px) {
    .Navbar--default .Navbar__Center, .Navbar--login .Navbar__Center {
      max-width: none; } }

.Navbar__Right {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 .5rem; }
