.InlineEditable {
  position: relative;
  line-height: 1.2; }

.InlineEditable__Link {
  color: inherit; }

.InlineEditable__Error {
  color: #f24343;
  font-size: 0.8em; }

.InlineEditable__Actions {
  position: absolute;
  z-index: 2;
  padding: 3px;
  background: #f0f0f0;
  border: 1px solid #c7c7c7;
  right: 0; }
  .InlineEditable__Actions--top {
    bottom: 100%; }
  .InlineEditable__Actions--bottom {
    top: 100%; }
