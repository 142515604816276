.NavbarItem {
  padding: 0 .2em;
  white-space: nowrap;
  flex-shrink: 0; }
  @media (min-width: 576px) {
    .NavbarItem {
      padding: 0 .5rem; } }

.NavbarItem--push {
  margin-left: auto; }
