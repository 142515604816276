.RichText__Editor {
  box-sizing: border-box;
  cursor: text;
  padding: 0.563rem 0.75rem;
  background: white;
  border: 1px solid #c7c7c7;
  border-radius: 2px;
  transition: border-color 150ms ease; }
  .RichText__Editor:hover {
    border-color: #8c8c8c; }
  .RichText__Editor--active, .RichText__Editor:focus {
    outline: none;
    border-color: #0a86cd;
    box-shadow: inset 0 5px 7px #f0f0f0; }
  .RichText__Editor--error {
    border-color: #ed1717; }
  .RichText__Editor--marked {
    background-color: #fbffb7; }
  .RichText__Editor--rounded {
    border-radius: 15px; }
  .RichText__Editor--error {
    border-color: #ed1717 !important; }
  .RichText__Editor .ql-tooltip {
    z-index: 100; }

.RichText__Editor--readonly {
  border: 0;
  padding: 0;
  cursor: normal; }

.RichText__Controls {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 5px;
  user-select: none; }

.RichText__StyleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block; }

.RichText__StyleButton--active {
  color: #5890ff; }
