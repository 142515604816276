$badge-sizes: (
  xs: 0.52rem,
  sm: 0.72rem,
  md: 0.9rem,
  lg: 1.2rem,
  xl: 1.5rem
);

$badge-rect-transforms: (
  ('left', 'top', scale(0) translate(-50%, -50%), scale(1) translate(-50%, -50%)),
  ('right', 'top', scale(0) translate(50%, -50%), scale(1) translate(50%, -50%)),
  ('left', 'bottom', scale(0) translate(-50%, 50%), scale(1) translate(-50%, 50%)),
  ('right', 'bottom', scale(0) translate(50%, 50%), scale(1) translate(50%, 50%))
);

.Wrapper {
  display: inline-block;
  position: relative;
}

.Badge {
  position: absolute;
  z-index: 2;
  border-radius: 100%;
  overflow: hidden;
  transform-origin: center;
  transition: transform .3s ease-out;

  &.left { left: 0; }
  &.right { right: 0; }
  &.top { top: 0; }
  &.bottom { bottom: 0; }
  
    &.shape-circle {
      transform: scale(0);
      &.visible {
        transform: scale(1);
      }
    }
  
    &.shape-rectangle {
      @each $h-pos, $v-pos, $hide-transform, $show-transform in $badge-rect-transforms {
        &.#{$h-pos} {
          &.#{$v-pos} {
            transform: $hide-transform;
            &.visible {
              transform: $show-transform;
            }
          }
        }
      }
    }
}

.SimpleBadge {
  font-size: 0;

    @each $name, $size in $badge-sizes {
      &.size-#{$name} {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: $size;
        min-height: $size;
        padding: 0 0.1em;
        font-size: $size;
        font-weight: 700;
      }
  }
}