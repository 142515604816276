.ChallengeFilter, .Content {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.ChallengeFilter {
  height: 100%;
  padding: 1rem;
}

.List {
  overflow-y: auto;
}

.Row {
  display: block;
}

.Footer {
  margin-top: auto;
  padding-top: 1em;
  text-align: right;

  > *:not(:last-child) {
    margin-right: 5px;
  }
}