.backdrop {
  z-index: 10300;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transition: all 300ms ease-in-out; }
  .backdrop--dark {
    background-image: url("./background.png");
    background-repeat: repeat; }
  .backdrop--light {
    background: rgba(255, 255, 255, 0.9); }
  .backdrop--brainstorming-preview {
    background: #fff url("./preview.png") no-repeat;
    background-size: cover; }
  .backdrop--hidden {
    visibility: hidden;
    opacity: 0; }
