.Heading {
  display: block; }
  .Heading--start, .Heading--left {
    text-align: left; }
  .Heading--center, .Heading--middle {
    text-align: center; }
  .Heading--end, .Heading--right {
    text-align: right; }

.Heading--1 {
  font-size: 1.6rem;
  margin: .67em 0; }

.Heading--2 {
  font-size: 1.4rem;
  margin: .67em 0; }

.Heading--brand {
  font-weight: normal;
  letter-spacing: 1px; }
  @supports ((background-clip: text) or (-webkit-background-clip: text)) and ((text-fill-color: transparent) or (-webkit-text-fill-color: transparent)) {
    .Heading--brand {
      background-image: linear-gradient(to right, #3EDE91, #377dd4);
      background-size: cover;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent; } }

.Heading--info {
  color: #5097e7; }
