@import 'scss/variables';

.Radio {
  cursor: pointer;
  font-size: $font-size-md;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: $border-radius-base;
  user-select: none;

  &:focus, &:focus-within {
    border-color: $brand-primary !important;
  }

  &.checked {
    border-color: #bbcddc;
  }

  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}


.Icon {
  color: $gray-5;
  vertical-align: sub;
  font-size: 1.6rem;

  .checked & {
    color: $brand-success;
  }
}