.QuickConceptForm {
  padding-bottom: .5rem;
  background: white; }
  .QuickConceptForm form {
    margin: 0; }
  .QuickConceptForm__Header {
    margin-bottom: 3rem;
    padding-bottom: 1rem !important;
    border-bottom: 1px solid #dcdcdc; }
  .QuickConceptForm__Body {
    padding: 0 !important; }
  .QuickConceptForm__Fields {
    padding: 0 30px; }

@media (min-width: 768px) and (orientation: landscape) {
  .QuickConceptForm .FormControl__Fields {
    flex-basis: 65%; } }
  .QuickConceptForm .FormControl__Fields .Select {
    width: 70%; }
  .QuickConceptForm__Actions {
    padding: 0 30px;
    text-align: right; }
    .QuickConceptForm__Actions Button {
      margin-left: 1rem; }
