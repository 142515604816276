@keyframes slideDown {
  from {
    max-height: 0; }
  to {
    max-height: 200px; } }

.Callout {
  display: block;
  text-align: center;
  font-size: 0.9rem;
  padding: .2em; }
  .Callout a {
    color: currentColor;
    text-decoration: underline;
    cursor: pointer; }

.Callout--warning {
  border-top: 1px solid #ebe0e2;
  background: #f2932e;
  color: #fff; }

.Callout--success {
  background: #549738;
  color: #fff; }

.Callout--info {
  background: #f2f6f9;
  color: #4aa1ce; }

.Callout--danger {
  background: #f24343;
  color: #fff;
  animation: slideDown 1.5s; }

.Callout--alert {
  background: #fa9b7b;
  color: #fff;
  border-top: 1px solid #dc7755;
  border-bottom: 1px solid #dc7755; }

.Callout--payment-info {
  background: #a5c2d0;
  color: #fff;
  border-top: 1px solid #a5c2d0;
  border-bottom: 1px solid #a5c2d0;
  font-weight: bold; }
  .Callout--payment-info a {
    color: #09425e; }

.Callout--spacing-1 {
  padding: .5rem 1rem; }
