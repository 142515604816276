.TagsInput__Tag {
  background: #c9e6bc linear-gradient(to bottom, #cee8c3, #c4e4b7);
  border-radius: 5px;
  border: 1px solid #8bc076;
  color: #345d24;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: normal;
  margin-bottom: .5rem;
  margin-right: .5rem;
  padding: 0 .5rem;
  line-height: 1.3;
  font-weight: 600; }
  .TagsInput__Tag--invalid {
    border-color: #f9a2a2;
    background: #f24343 linear-gradient(to bottom, #fababa, #f9a2a2); }
    .TagsInput__Tag--invalid .TagValue {
      text-decoration: line-through; }
  .TagsInput__Tag--error {
    border-color: #e24667 !important; }

.TagsInput__Remove {
  cursor: pointer;
  font-weight: bold;
  vertical-align: middle;
  font-size: 1.4rem;
  color: #345d24;
  padding: 0 .1rem;
  margin-left: .3rem; }

.TagsInput__Input {
  margin-top: .5rem;
  border: 1px solid #c7c7c7;
  border-radius: 2px;
  transition: border-color 150ms ease; }
  .TagsInput__Input:hover {
    border-color: #8c8c8c; }
  .TagsInput__Input--active, .TagsInput__Input:focus {
    outline: none;
    border-color: #0a86cd;
    box-shadow: inset 0 5px 7px #f0f0f0; }
  .TagsInput__Input--error {
    border-color: #ed1717; }
  .TagsInput__Input--marked {
    background-color: #fbffb7; }
  .TagsInput__Input--rounded {
    border-radius: 15px; }
