.slide-transition-enter {
  transform: translateX(110%);
  will-change: transform; }

.slide-transition-enter.slide-transition-enter-active {
  transform: translateX(0); }

.slide-transition-exit {
  will-change: transform;
  transform: translateX(0); }

.slide-transition-exit.slide-transition-exit-active {
  transform: translateX(110%); }
