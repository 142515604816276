.Embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .Embed::before {
    display: block;
    content: ""; }
  .Embed .Embed__Item,
  .Embed embed,
  .Embed iframe,
  .Embed object,
  .Embed video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.Embed--ratio-16by9::before {
  padding-top: 56.25%; }
