.DateTimeInput {
  display: flex;
  align-items: stretch;
  max-width: 300px;
  border: 1px solid #c7c7c7;
  border-radius: 2px;
  transition: border-color 150ms ease; }
  .DateTimeInput:hover {
    border-color: #8c8c8c; }
  .DateTimeInput--active, .DateTimeInput:focus {
    outline: none;
    border-color: #0a86cd;
    box-shadow: inset 0 5px 7px #f0f0f0; }
  .DateTimeInput--error {
    border-color: #ed1717; }
  .DateTimeInput--marked {
    background-color: #fbffb7; }
  .DateTimeInput--rounded {
    border-radius: 15px; }

.DateTimeInput__Icon {
  background: #e2e2e2;
  color: #868686;
  display: flex;
  align-items: center;
  justify-content: center; }

.DateTimeInput__Input {
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: 0; }
  .DateTimeInput__Input:focus {
    box-shadow: none; }
