.Pagination__Nav {
  margin-left: -3px;
  margin-right: -3px; }
  .Pagination__Nav__Btn, .Pagination__Nav__Btn--left, .Pagination__Nav__Btn--right, .Pagination__Nav__Placeholder {
    width: 40px;
    height: 40px;
    margin: 0 3px; }
  .Pagination__Nav__Btn, .Pagination__Nav__Btn--left, .Pagination__Nav__Btn--right {
    padding: 0;
    border-color: #ced1d4;
    transition: border-color .25s linear;
    text-align: center; }
    .Pagination__Nav__Btn, .Pagination__Nav__Btn--left, .Pagination__Nav__Btn--right, .Pagination__Nav__Btn:hover, .Pagination__Nav__Btn--left:hover, .Pagination__Nav__Btn--right:hover, .Pagination__Nav__Btn:focus, .Pagination__Nav__Btn--left:focus, .Pagination__Nav__Btn--right:focus {
      color: #4a4a4a; }
    .Pagination__Nav__Btn--left {
      box-shadow: inset 3px 0px 0 0px #3EDE91; }
    .Pagination__Nav__Btn--right {
      box-shadow: inset -3px 0px 0 0px #3EDE91; }
  .Pagination__Nav__Placeholder {
    display: inline-block;
    vertical-align: middle; }
