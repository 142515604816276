.PaginationItem {
  display: inline; }

.PaginationItem__Button {
  font-size: 0.9rem;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0; }
  .PaginationItem:first-child .PaginationItem__Button {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .PaginationItem:last-child .PaginationItem__Button {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .PaginationItem__Button:focus, .PaginationItem__Button:hover {
    z-index: 2;
    background-color: #efefef;
    border-color: #ddd; }
  .PaginationItem--active .PaginationItem__Button,
  .PaginationItem--active .PaginationItem__Button:focus,
  .PaginationItem--active .PaginationItem__Button:hover {
    z-index: 3;
    cursor: default;
    background-color: #efefef;
    border-color: #ddd; }
  .PaginationItem--disabled .PaginationItem__Button,
  .PaginationItem--disabled .PaginationItem__Button:focus,
  .PaginationItem--disabled .PaginationItem__Button:hover {
    color: #c2c2c2;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #efefef; }
