.SecondaryNavigation__ActiveGroup {
  color: #29b987;
  font-size: 0.9rem; }
  @media (min-width: 768px) {
    .SecondaryNavigation__ActiveGroup {
      display: none; } }

.SecondaryNavigation__Items {
  overflow-y: auto;
  white-space: nowrap;
  width: 100%;
  padding-bottom: 1rem; }
  @media (min-width: 768px) {
    .SecondaryNavigation__Items {
      padding-bottom: 0; } }
