.PrimaryNavigationButton {
  vertical-align: bottom; }
  @media (min-width: 768px) {
    .PrimaryNavigationButton {
      margin-right: 6%; } }
  .PrimaryNavigationButton.onboarding-highlight {
    background: #fff; }

.PrimaryNavigation--around .PrimaryNavigationButton {
  margin-right: 0; }

.PrimaryNavigationButton__Link {
  color: #424242;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  padding: .25rem 1rem;
  margin: 0 .25rem .25rem .25rem;
  cursor: pointer;
  transition: color .3s, background .3s, border-color .3s; }
  @media (min-width: 768px) {
    .PrimaryNavigationButton__Link {
      border-bottom: 4px solid transparent;
      margin: 0;
      padding: .25rem .5rem .8rem; } }

.PrimaryNavigationButton__Link--active,
.PrimaryNavigationButton__Link:focus {
  background: #68a9f9;
  color: #fff; }
  @media (min-width: 768px) {
    .PrimaryNavigationButton__Link--active,
    .PrimaryNavigationButton__Link:focus {
      background: none;
      color: #424242;
      border-color: #68a9f9; } }
