.OnboardingTipPopover {
  font-size: 1rem;
  padding: .25rem 1rem 0; }

.OnboardingTipPopover__Title {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: .5rem; }

.OnboardingTipPopover__Body {
  font-size: 0.9rem;
  margin: 0; }
