.GridList {
  display: grid;
  grid-gap: 20px; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .GridList {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-left: -20px;
      margin-right: -20px; }
      .GridList > * {
        width: calc(33% - 20px);
        margin: 20px 10px 0 !important; }
        .GridList > *:nth-child(1) {
          margin-top: 0; }
        .GridList > *:nth-child(2) {
          margin-top: 0; }
        .GridList > *:nth-child(3) {
          margin-top: 0; } }
  @media (max-width: 767px) {
    .GridList {
      display: flex;
      flex-direction: column;
      align-items: stretch; } }
  @media all and (max-width: 767px) and (-ms-high-contrast: none), (max-width: 767px) and (-ms-high-contrast: active) {
    .GridList > * {
      width: 100%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .GridList {
    grid-template-areas: "a b" "c d";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr); } }
  @media all and (min-width: 768px) and (max-width: 991px) and (-ms-high-contrast: none), (min-width: 768px) and (max-width: 991px) and (-ms-high-contrast: active) {
    .GridList > * {
      width: calc(50% - 20px); } }
  @media (min-width: 992px) {
    .GridList {
      grid-template-columns: repeat(3, 1fr); } }
