.CheckboxButtonGroup {
  white-space: nowrap;
  margin: 0 auto; }

.CheckboxButton {
  display: inline-block;
  padding: .35em .75em .3em;
  border: 1px solid #c7c7c7;
  border-right-width: 0;
  background: #fff;
  text-align: center;
  cursor: pointer;
  font-weight: normal;
  transition: background 200ms, color 200ms; }
  .CheckboxButton:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  .CheckboxButton:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-right-width: 1px; }

.CheckboxButton--checked {
  color: #fff;
  border-color: #4b86f2;
  background: #4b86f2; }

.CheckboxButton--checked + .CheckboxButton {
  border-left-color: #fff; }

.CheckboxButton--disabled {
  color: #dddddd;
  border: 1px solid #ececec;
  cursor: not-allowed; }

.CheckboxButton__Input {
  position: absolute;
  clip: rect(0, 0, 0, 0); }
