.IconWrapper {
  display: inline-block; }

.Icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  stroke: currentColor;
  stroke-width: 0;
  width: 1em; }
  .Icon--clickable {
    padding: 5px;
    color: #a1a1a1;
    cursor: pointer; }
    .Icon--clickable:hover, .Icon--clickable:focus {
      color: #6b6b6b; }

.Icon--xs {
  font-size: .5em; }

.Icon--sm {
  font-size: .7em; }

.Icon--md {
  font-size: .9em; }

.Icon--lg {
  font-size: 1.4em; }

.Icon--xl {
  font-size: 2.2em; }

.Icon--bulb {
  height: 1.12644em; }

.Icon--success {
  color: #61b73e; }

.Icon--polygon-1 {
  stroke-width: .3; }

.Icon--polygon-2 {
  stroke-width: .3; }

.Icon--email {
  width: 2.463247em;
  vertical-align: middle; }

.Icon--dnd {
  font-size: 2rem; }

.Icon--forward-framed {
  border: 1px solid #c7c7c7;
  border-radius: 2px;
  padding: 1px;
  font-size: 1.1rem; }

.Icon--network {
  stroke-width: initial;
  width: 1.4em; }

.Icon--chain {
  width: 1.4em; }

.Icon--clock {
  height: 1.1695em; }

.Icon--forward {
  height: 1.49em;
  vertical-align: middle; }

.Icon--color-warning {
  color: #ba6f1e; }

.Icon--color-info {
  color: #4b7ba0; }

.Icon--color-danger {
  color: #ff0b00; }

.Icon--color-success {
  color: #83b436; }

.Icon--color-light {
  color: #d8c419; }

.Icon--color-neutral {
  color: #7085a7; }
