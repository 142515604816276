.Autosuggest__Container {
  position: relative; }

.Autosuggest__Input {
  height: 40px;
  padding: 0.563rem 0.75rem;
  font-weight: 300;
  border: 1px solid #c7c7c7;
  border-radius: 2px;
  transition: border-color 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A@{arrow}%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  padding-right: 2rem; }
  .Autosuggest__Input:hover {
    border-color: #8c8c8c; }
  .Autosuggest__Input--active, .Autosuggest__Input:focus {
    outline: none;
    border-color: #0a86cd;
    box-shadow: inset 0 5px 7px #f0f0f0; }
  .Autosuggest__Input--error {
    border-color: #ed1717; }
  .Autosuggest__Input--marked {
    background-color: #fbffb7; }
  .Autosuggest__Input--rounded {
    border-radius: 15px; }
  .Autosuggest__Input--error {
    border-color: #e24667; }
  .Autosuggest__Container--open .Autosuggest__Input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

.Autosuggest__SuggestionsContainer {
  display: none; }
  .Autosuggest__Container--open .Autosuggest__SuggestionsContainer {
    display: block;
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 5;
    font-size: 0.9rem; }

.Autosuggest__SuggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.Autosuggest__Suggestion {
  cursor: pointer;
  padding: 0.563rem 0.75rem; }

.Autosuggest__Suggestion--focused {
  background-color: #ddd; }
