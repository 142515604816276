// Variables

// Breakpoints
// Screen width
$xs-screen: 320px;
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

// Screen height
$lg-min-height: $md-screen;

$breakpoints: (
  xs: $xs-screen,
  sm: $sm-screen,
  md: $md-screen,
  lg: $lg-screen,
  xl: $xl-screen
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
) !default;

// Define your breakpoints for Neat layout
$tablet: new-breakpoint(max-width $md-screen 8);
$mobile: new-breakpoint(max-width 480px 4);

$im-columns-class: '.Col' !default;
$im-columns-gutters: (
  0: 0,
  1: 10px,
  2: 20px,
  3: 40px,
  4: 100px
);

// Layout container width
$desktop-max-width: 2560px;
$desktop-width: 95vw;

// Neat Grid Settings
$column: 30px;
$gutter: 0px;
$grid-columns: 12;
$max-width: em(1088);
$border-box-sizing: true;

// For development only
$visual-grid: false;
$visual-grid-color: #333;
$visual-grid-index: back;
$visual-grid-opacity: 0.9;

// Own grid list settings
$grid-list-grid-gap: 20px;

// Modal sizes
$modal-sm: 360px;
$modal-md: 460px;
$modal-lg: 900px;

// Colors
$gray-dark-1: #020a11;
$gray-dark-2: #1e1a1a;
$gray-dark-3: #2f2c2c;
$gray-dark-4: #000000; // just placeholder, haven't used yet
$gray-dark-5: #000000; // just placeholder, haven't used yet
$gray-1: #4a4a4a;
$gray-2: #6b6b6b;
$gray-333: #333;
$gray-3: #939393;
$gray-999: #999;
$gray-9a: #9a9a9a;
$gray-4: #a1a1a1;
$gray-5: #acabab;
$gray-b2: #b2a6a6;
$gray-b6: #b6b6b6;
$gray-light-1: #c7c7c7;
$gray-ce: #ced1d4;
$gray-light-2: #d1d1d1;
$gray-light-3: #dcdcdc;
$gray-ddd: #ddd;
$gray-ec: #ececec;
$gray-light-4: #efeeee;
$gray-light-5: #f0f0f0;
$gray-light-6: #feffff;
$link-primary-color: #377dd4;
$link-primary-color-hover: lighten($link-primary-color, 3%);
$link-light-color: #3EDE91;

// Background colors
$base-bg: #fff;
$header-bg: $base-bg;
$notice-bg-yellow: #eadc62;
$highlight-bg-yellow: #fefee9;
$fullscreen-overview-bg: #dde7ea;
$fullscreen-form-bg: #d3eaf7;
$fullscreen-result-bg: #f2f6f9;
$fullscreen-light-bg: #E5EEEF;

$brand-primary: #2372cb;
$brand-success: #61b73e;
$brand-info: #c4e0ff;
$brand-danger: #f24343;
$brand-warning: #e8b810;
$brand-gradient-start: #3EDE91;
$brand-gradient-end: #377dd4;
$brand-mark: #fbffb7;

// Color palette for random user colors
$user-palette: (
  0: #3ede91ff,
  1: #377dd4ff,
  2: #0256b0ff,
  3: #053d82ff,
  4: #3d026eff,
  5: #b378e3ff,
  6: #c4065cff,
  7: #543208ff,
  8: #d97811ff,
  9: #0c6b40ff
);

// Accent Colors
$accent-blue-37: #3781d1;
$accent-blue-70: #7097a5;
$accent-blue-b3: #b3d4fc;
$accent-blue-c0: #1565c0;
$accent-blue-cc: #0077cc;
$accent-blue-d6: #4a90d6;
$accent-blue-f9: #68a9f9;
$accent-blue-fc: #87c3fc;
$accent-green-3e: #3edb93;
$accent-pink-ff: #ffa2cc;
$accent-orange-da: #dad6b5;
$accent-orange-ee: #eebb00;
$accent-orange-f7: #f77441;
$accent-orange-ff: #ffc271;
$quick-idea-concept-color: #62a4b7;

$list-marker-color: #22ca2d;

$font-size-base: 1rem !default;
$font-size-xl: 1.2rem !default;
$font-size-lg: 1rem !default;
$font-size-md: .9rem !default;
$font-size-sm: .8rem !default;
$font-size-xs: .7rem !default;
$font-size-xxs: .6rem !default;

$icon-variants: (
  warning: #ba6f1e,
  info: #4b7ba0,
  danger: #ff0b00,
  success: #83b436,
  light: #d8c419,
  neutral: #7085a7
);

$box-shadow-base: 0 0 10px 4px rgba(0,0,0,0.1);

// Input

$input-base-border-color: $gray-light-1;
$input-hover-border-color: #8c8c8c;
$input-focus-border-color: #0a86cd;
$input-error-border-color: #ed1717;
$input-marked-backgorund-color: $brand-mark;

$input-padding-x: .75rem !default;
$input-padding-y: .563rem !default;
$input-padding-x-lg: 1.5rem !default;
$input-padding-y-lg: 0.635rem !default;

$zindex-fixed: 10200 !default;
$zindex-backdrop: 10300 !default;
$zindex-modal: 10400 !default;
$zindex-drawer: 10500 !default;
$zindex-popover: 10600 !default;
$zindex-dropdown: 10700 !default;
$zindex-tooltip: 10800 !default;
$zindex-alert: 10900 !default;
$zindex-intercom: 11000 !default;
$zindex-cookiestripe: 11100 !default;

$border-radius-base: 5px;
$border-radius-small: 2px;
$border-radius-large: 8px;

// Alerts
$alert-success-background: #e8f7ed;
$alert-success-border-color: #30b661;
$alert-success-color: darken(#30b661, 15%);

$alert-info-background: rgba(88,97,110,0.9);
$alert-info-border-color: transparent;
$alert-info-color: #fff;

$alert-warning-background: #fcf8e3;
$alert-warning-border-color: #faebcc;
$alert-warning-color: #8a6d3b;

$alert-danger-background: #e24667;
$alert-danger-border-color: #e24667;
$alert-danger-color: #fff;

// Icon colors
$card-icon-color: #695b5b;
$card-icon-hover-color: #373131;
$card-icon-visited-color: #aea2a2;

// Triangle
$triangle-size: 10px !default;
$triangle-border-width: 1px !default;
$triangle-border-color: $gray-light-1 !default;
$triangle-background-color: #ffffff !default;

// Like animation variables
$heart-icon-active-color: #d54949;

$avatar-sizes: (
  xs: 1.75rem,
  sm: 2.375rem,
  md: 3rem,
  lg: 4rem,
  xl: 5rem
);

// Results page idea concept card variables
$results-concept-card-side-padding: 21px;