.ProfileTab__Name {
  display: flex;
  align-items: center; }

.ProfileTab__AdminLabel {
  background-color: #588cf6;
  font-size: .9rem; }

.ProfileTab__BasicInfo h1, .ProfileTab__BasicInfo h3 {
  margin: .5rem 0px;
  line-height: 1; }

.ProfileTab__LocationIcon {
  color: #c92429; }

.ProfileTab__HeaderInfo {
  display: flex;
  align-items: center; }
  .ProfileTab__HeaderInfo.Link {
    display: inline;
    color: #333; }
    .ProfileTab__HeaderInfo.Link:active, .ProfileTab__HeaderInfo.Link:focus, .ProfileTab__HeaderInfo.Link:hover {
      color: #1565c0; }
  .ProfileTab__HeaderInfo .Icon {
    margin-right: 8px;
    vertical-align: initial; }

.ProfileTab hr {
  border-color: #b6b6b6; }
