.PrimaryNavigation {
  position: relative;
  border-bottom: 1px solid #ebe0e2;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end; }

.PrimaryNavigation--around {
  justify-content: space-around; }

.PrimaryNavigation--scrollable {
  overflow-y: auto; }

.PrimaryNavigation--wrap {
  flex-wrap: wrap; }
