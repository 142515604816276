.FullScreen {
  min-height: 100vh;
  padding-bottom: 10vh;
  margin: 0;
  overflow-x: hidden; }

.FullScreen--overview {
  background: #dde7ea; }

.FullScreen--form {
  background: #d3eaf7; }

.FullScreen--result {
  background: #f2f6f9; }

.FullScreen--light {
  background: #E5EEEF; }

.FullScreen--padding-none {
  padding-bottom: 0vh !important; }
