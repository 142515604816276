.Panel {
  margin-bottom: 1rem; }

.Panel--rounded, .Panel--rounded .Panel__Header, .Panel--bordered, .Panel--bordered .Panel__Header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.Panel--rounded .Panel__Header + .Panel__Body, .Panel--bordered .Panel__Header + .Panel__Body {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.Panel--rounded, .Panel--rounded .Panel__Body, .Panel--bordered, .Panel--bordered .Panel__Body {
  border-radius: 5px; }

.Panel--shadowed {
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1); }

.Panel--clickable {
  cursor: pointer; }

.Panel__Body,
.Panel__Header {
  display: block;
  margin: 0;
  background-color: #fff; }
  .Panel--spacing-md .Panel__Body, .Panel--spacing-md
  .Panel__Header {
    padding: 1rem; }
    @media (min-width: 576px) {
      .Panel--spacing-md .Panel__Body, .Panel--spacing-md
      .Panel__Header {
        padding: 1rem 1.2rem; } }
  .Panel--spacing-lg .Panel__Body, .Panel--spacing-lg
  .Panel__Header {
    padding: 1rem 1rem 2rem; }
    @media (min-width: 576px) {
      .Panel--spacing-lg .Panel__Body, .Panel--spacing-lg
      .Panel__Header {
        padding: 2rem 2.5rem 2rem; } }

.Panel--bordered {
  background: white; }
  .Panel--bordered .Panel__Body {
    background-image: linear-gradient(to right, #3EDE91, #377dd4);
    background-size: 100% 7px;
    background-repeat: no-repeat;
    background-position: bottom center; }

.Panel--smoked {
  border: 1px solid #ddd8d8; }
  .Panel--smoked .Panel__Body {
    background: #dde7ea; }
