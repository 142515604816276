.Separator {
  display: table;
  margin: 1rem 0;
  white-space: nowrap; }
  .Separator:after, .Separator:before {
    border-top: 1px solid #c7c7c7;
    content: '';
    display: table-cell;
    position: relative;
    top: .8em;
    width: 50%; }

.Separator__Content {
  padding: 0 20px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: bold; }
