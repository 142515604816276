.Textarea {
  border: 1px solid #c7c7c7;
  border-radius: 2px;
  transition: border-color 150ms ease; }
  .Textarea:hover {
    border-color: #8c8c8c; }
  .Textarea--active, .Textarea:focus {
    outline: none;
    border-color: #0a86cd;
    box-shadow: inset 0 5px 7px #f0f0f0; }
  .Textarea--error {
    border-color: #ed1717; }
  .Textarea--marked {
    background-color: #fbffb7; }
  .Textarea--rounded {
    border-radius: 15px; }
  .Textarea:not(.Textarea--resizable) {
    resize: none; }
