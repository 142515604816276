.Wrapper {
  padding: 1rem;
  border: 1px solid #ccc;
  text-align: center;
}

.ContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Img {
  display: block;
  max-width: 100%;
  max-height: 300px;
  margin: 0 auto;
}

.Tools {
  margin-top: 1em;
  > * {
    margin: 0 10px;
  }
}
