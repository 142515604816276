@import 'scss/variables';

$bar-height: 14px;

.Table {
  width: 100%;
  font-size: 0.9em;
  line-height: $bar-height;
  font-weight: 500;
  color: $gray-2;

  th, td {
    padding: .3em;
    vertical-align: top;
  }

  th {
    padding-left: 0;
  }

  td:last-of-type {
    padding-right: 0;
  }
}

.Header {
  width: 30%;
  text-align: left;
  font-weight: normal;
  
  .longLabels & {
    width: 70%;
  }
}

@keyframes grow {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.BarGraph {
  height: $bar-height;
  border-radius: 2px;
  background: linear-gradient(
    to right,
    $brand-gradient-start,
    $brand-gradient-end
  );
  will-change: width, transform;
  transform-origin: left center;
  transform: width 0.8s ease-out;
  animation: 0.8s grow ease-out;
}

.Score {
  width: 50px;
  font-weight: bold;
  text-align: right;
  color: $brand-gradient-end;
}