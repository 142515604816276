.SecondaryNavigationGroup {
  margin-top: 1.5rem;
  display: inline; }
  @media (min-width: 768px) {
    .SecondaryNavigationGroup {
      display: block; } }

.SecondaryNavigationGroup__Items {
  display: inline; }
  @media (min-width: 768px) {
    .SecondaryNavigationGroup__Items {
      display: block; } }

.SecondaryNavigationGroup__Title {
  display: none;
  color: #29b987;
  font-size: 0.9rem; }
  @media (min-width: 768px) {
    .SecondaryNavigationGroup__Title {
      display: block; } }
