@media (min-width: 320px) {
  .hidden-up-xs {
    display: none !important; } }

@media (max-width: 319px) {
  .hidden-down-xs {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-up-sm {
    display: none !important; } }

@media (max-width: 575px) {
  .hidden-down-sm {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-up-md {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-down-md {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-up-lg {
    display: none !important; } }

@media (max-width: 991px) {
  .hidden-down-lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-up-xl {
    display: none !important; } }

@media (max-width: 1199px) {
  .hidden-down-xl {
    display: none !important; } }
