.Close {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 1;
  cursor: pointer;
  border: 0;
  padding: 0;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 0.8rem; }
  .Close, .Close:active, .Close:focus {
    background: transparent;
    color: #2f2c2c; }
  .Close:hover {
    background: transparent;
    color: #6b6b6b; }

.Close--inverse, .Close--inverse:active, .Close--inverse:focus {
  color: #a1a1a1; }

.Close--inverse:hover {
  color: #f0f0f0; }

.Close__Icon {
  font-size: 1.75rem;
  padding: 0 5px;
  vertical-align: middle;
  display: inline-block; }
