.Media {
  display: flex;
  justify-content: space-between;
  color: #424242; }
  .Media:hover {
    color: #424242; }
  .Media--align-top {
    align-items: flex-start; }
  .Media--align-middle {
    align-items: center; }
  .Media--align-bottom {
    align-items: flex-end; }
  .Media--hideOverflow .Media__Body {
    overflow: hidden;
    text-overflow: ellipsis; }

.Media__Body {
  flex-grow: 1;
  word-break: break-word; }

.Media__Right {
  padding-left: 10px; }

.Media__Left {
  padding-right: 10px; }

.Media--active {
  background: #e7f3fb; }

.Media--clickable {
  cursor: pointer; }
  .Media--clickable:hover {
    background: #d8ecfa; }
