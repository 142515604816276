.Textarea {
  position: relative;
  cursor: text;
  line-height: 1.2;
  padding: 0.563rem 0.75rem;
  word-wrap: break-word;
  word-break: break-word;
  border: 1px solid #c7c7c7;
  border-radius: 2px;
  transition: border-color 150ms ease; }
  .Textarea:hover {
    border-color: #8c8c8c; }
  .Textarea--active, .Textarea:focus {
    outline: none;
    border-color: #0a86cd;
    box-shadow: inset 0 5px 7px #f0f0f0; }
  .Textarea--error {
    border-color: #ed1717; }
  .Textarea--marked {
    background-color: #fbffb7; }
  .Textarea--rounded {
    border-radius: 15px; }
  .Textarea .public-DraftEditor-content[contenteditable='true'] {
    user-select: text; }

.public-DraftEditorPlaceholder-inner {
  font-size: 0.85em;
  color: #5d636f; }
