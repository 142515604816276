.MediaList {
  padding-left: 0;
  list-style: none;
  background: #fff;
  font-size: 0.9rem; }
  .MediaList li + li {
    border-top: 1px solid #bbbfc1; }

.MediaList__Item {
  padding: 1rem; }

.MediaList--condensed {
  font-size: 0.8rem; }
  .MediaList--condensed .MediaList__Item {
    padding: .5rem; }
