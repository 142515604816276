.Label {
  display: inline;
  padding: .2em .5em;
  margin-left: .5em;
  font-size: 80%;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }
  .Label--rounded {
    border-radius: 3px; }
  .Label--default {
    background-color: #b6b6b6; }
  .Label--info {
    background-color: #8db0ce; }
  .Label--success {
    background-color: #61b73e; }
  .Label svg {
    height: .75em;
    vertical-align: middle; }
