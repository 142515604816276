@import 'scss/variables';
@import 'scss/mixins/responsive';

.Btn {
  position: relative;
  display: flex;
  flex-direction: column;
  appearance: none;
  border: none;
  padding: 1rem .8rem;
  background: none;
  text-align: center;
  font-weight: 600;
  line-height: 1;
  color: $gray-1; 
  cursor: pointer;

  &:not([disabled]):hover {
    color: $brand-primary;
  }

  &.active:after {
    position: absolute;
    content: '';
    border-bottom: 4px solid $accent-blue-f9;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
  }

  @include above-mobile-large {
    display: block;
  }
}