@import 'scss/mixins';

.StageRow {
  display: flex; 
  justify-content: space-between;

  @include below-tablet {
    flex-direction: column-reverse;
  }
}

.StageFilters {
  border-bottom: none;

  @include below-tablet {
    overflow-y: auto;
  }
}

.ChboxWrapper {
  > * {
    margin-right: 2em;
  }
}