.Container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 576px) {
    .Container {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (min-width: 576px) {
    .Container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .Container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .Container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .Container {
      max-width: 1170px; } }
  @media (min-width: 576px) {
    .Container\@sm {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .Container\@md {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .Container\@lg {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .Container\@xl {
      max-width: 1170px; } }
