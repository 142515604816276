.ImagePreview {
  position: relative; }

.ImagePreview__Image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.ImagePreview__Button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  outline: 2px dashed #c9c9c9;
  outline-offset: -1px;
  border: 1px solid transparent;
  border-radius: 0; }
  .ImagePreview__Button, .ImagePreview__Button:active, .ImagePreview__Button:disabled, .ImagePreview__Button:focus, .ImagePreview__Button:hover {
    background-color: transparent; }
  .ImagePreview--selected .ImagePreview__Button {
    outline: none;
    border: 1px solid #c7c7c7;
    color: #fff;
    background: rgba(0, 0, 0, 0.35); }
