.menu__item {
  border-left: 3px solid transparent;
  margin-left: -3px;
  padding-bottom: 1px; }
  .menu__item, .menu__item:active, .menu__item:focus {
    background-color: transparent;
    color: #a1a1a1; }
  .menu__item:hover {
    color: #3EDE91;
    border-color: #3EDE91; }

.menu__item--highlighted, .menu__item--highlighted:active, .menu__item--highlighted:focus {
  color: #f24343; }

.menu__item--highlighted:hover {
  color: shade(#f24343, 20%);
  border-color: #f24343; }

.menu__link {
  border: 0;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-size: 1rem;
  white-space: normal;
  cursor: pointer; }
  .menu__link, .menu__link:active, .menu__link:focus {
    background-color: transparent;
    color: inherit; }
  .menu__link:hover {
    background-color: transparent;
    color: inherit; }

.menu__icon {
  width: 50px;
  height: 50px;
  padding: 12px; }

.menu__text {
  flex: 1;
  text-align: left;
  align-self: center; }

.submenu {
  padding: 5px;
  margin: 0 15px 20px;
  background-color: #020a11;
  border: 1px solid #6b6b6b; }

.submenu--collapsed {
  display: none; }

.submenu__link {
  display: block;
  cursor: pointer;
  padding: 5px 10px;
  color: #a1a1a1;
  text-transform: uppercase;
  font-size: 1rem; }
  .submenu__link:focus, .submenu__link:hover {
    color: #f0f0f0; }

.submenu__link--active {
  color: #f0f0f0; }
