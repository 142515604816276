.ConceptTable__Header {
  font-weight: bold;
  color: #6b6b6b; }

.ConceptTable__StageCol > div {
  display: block !important; }

.ConceptTable__HeaderCols {
  max-width: initial;
  flex: 0 1 300px; }

.ConceptTable__BodyCols {
  flex: 1 9 auto; }
