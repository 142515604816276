@keyframes ProgressMovingGradient {
  0% {
    background-position: left bottom; }
  100% {
    background-position: right bottom; } }

.Progress {
  height: 3px;
  background-size: 100% 100%;
  background: linear-gradient(to right, #3EDE91 0%, #377dd4 100%); }

.Progress--thin {
  height: 1px; }

.Progress--thick {
  height: 7px; }

.Progress--animate {
  background: linear-gradient(to right, #3EDE91 0%, #377dd4 50%, #3EDE91 100%);
  background-size: 50% 100%;
  animation-name: ProgressMovingGradient;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }
