.fade-transition-enter {
  opacity: 0; }

.fade-transition-enter.fade-transition-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in; }

.fade-transition-exit {
  opacity: 1; }

.fade-transition-exit.fade-transition-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-in; }
