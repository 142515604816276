.CircleSpinner {
  background: #fff;
  border-radius: 20px;
  margin-bottom: 1rem; }
  .CircleSpinner svg {
    max-height: 400px;
    margin: 0 auto; }

.CircleSpinner__Hummingbird {
  animation: CircleSpinnerHummingbirdAnimation linear infinite;
  animation-duration: 2500ms;
  animation-direction: alternate;
  fill: #895088; }

@keyframes CircleSpinnerHummingbirdAnimation {
  0% {
    fill: #32749e; }
  50% {
    fill: #4ed63f; }
  90% {
    fill: #895088; } }

.CircleSpinner__Circles {
  transform: rotate(-30deg); }
  .CircleSpinner__Circles circle {
    fill: none; }
    .CircleSpinner__Circles circle:nth-of-type(1) {
      animation: CircleSpinner__CircleAnimation1 2500ms linear infinite;
      animation-direction: normal;
      stroke-width: 9;
      stroke-dasharray: 488.54542, 1036.72558;
      stroke-dashoffset: -1036.72558; }

@keyframes CircleSpinner__CircleAnimation1 {
  0% {
    stroke-dashoffset: -1036.72558; }
  100% {
    stroke-dashoffset: 488.54542; } }
    .CircleSpinner__Circles circle:nth-of-type(2) {
      animation: CircleSpinner__CircleAnimation2 2500ms linear infinite;
      animation-direction: normal;
      stroke-width: 3;
      stroke-dasharray: 384.91457, 942.4778;
      stroke-dashoffset: -942.4778; }

@keyframes CircleSpinner__CircleAnimation2 {
  0% {
    stroke-dashoffset: -942.4778; }
  100% {
    stroke-dashoffset: 384.91457; } }
    .CircleSpinner__Circles circle:nth-of-type(3) {
      animation: CircleSpinner__CircleAnimation3 2500ms linear infinite;
      animation-direction: reverse;
      stroke-width: 10;
      stroke-dasharray: 133.23966, 848.23002;
      stroke-dashoffset: -848.23002; }

@keyframes CircleSpinner__CircleAnimation3 {
  0% {
    stroke-dashoffset: -848.23002; }
  100% {
    stroke-dashoffset: 133.23966; } }
