.NoticePanel, .NoticePanel--primary, .NoticePanel--success, .NoticePanel--info, .NoticePanel--danger, .NoticePanel--warning, .NoticePanel--mark, .NoticePanel--notice, .NoticePanel--highlight {
  display: block;
  margin: 1rem auto;
  padding: 1.25rem 1.875rem; }
  .NoticePanel > *:last-child, .NoticePanel--primary > *:last-child, .NoticePanel--success > *:last-child, .NoticePanel--info > *:last-child, .NoticePanel--danger > *:last-child, .NoticePanel--warning > *:last-child, .NoticePanel--mark > *:last-child, .NoticePanel--notice > *:last-child, .NoticePanel--highlight > *:last-child {
    margin-bottom: 0; }
  .NoticePanel--primary {
    background: #2372cb; }
  .NoticePanel--success {
    background: #61b73e; }
  .NoticePanel--info {
    background: #c4e0ff; }
  .NoticePanel--danger {
    background: #f24343; }
  .NoticePanel--warning {
    background: #e8b810; }
  .NoticePanel--mark {
    background: #fbffb7; }
  .NoticePanel--notice {
    background: #eadc62; }
  .NoticePanel--highlight {
    background: #fefee9; }
  .NoticePanel--isRounded {
    border-radius: 8px; }
