@import 'scss/mixins';
@import 'scss/variables';

.SearchField {
  font-size: $font-size-lg;
  font-style: italic;
  padding: $input-padding-y-lg $input-padding-x-lg;
  margin: 0;
  border-radius: 0;
}

.GalleryWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5rem;
}

.Image {
  width: 50%;
  flex: 0 0 auto;
  padding: 0.5rem;

  @include above-mobile-small {
    width: 33.333333%;
  }

  @include desktop {
    width: 25%;
  }

  .Link {
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 100%;
    z-index: 1;
    background: url('./placeholder.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
      transition: opacity 300ms;
    }

    &:hover:after {
      opacity: 1;
    }
  }

  .Background {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    max-width: initial;
    transform: translate(-50%, -50%);
    transition: all 300ms;
  }

  .Select {
    display: block;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    min-width: 75px;
    height: 0;
    padding: 0 5px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    text-shadow: 1px 1px 0px black;
    text-align: center;

    span {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &:after {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      transform: translateY(calc(-50% - 10px));
      content: ' ';
      padding-bottom: 100%;
      will-change: transform, opacity;
      transition: opacity 300ms;
      border: 1px solid white;
      border-radius: 100%;
      box-shadow: 0 0 4px rgba(black, 0.35), 0 0 4px rgba(black, 0.35) inset;
    }
  }

  .Link:hover {
    .Background {
      transform-origin: center center;
      transform: translate(-50%, -50%) scale(1.2);
      filter: grayscale(90%);
    }

    .Select {
      transition: opacity 300ms;
      opacity: 1;
    }
  }
}

.Uploader {
  text-align: center;

  .Error {
    color: $brand-danger;
  }

  .DropZone {
    width: 100%;
    height: 200px;
    border: 2px dashed $gray-light-1;
    border-radius: $border-radius-base;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    &.active {
      border-color: $brand-success;
    }

    &.rejected {
      border-color: $brand-danger;
    }
  }

  .Slider {
    margin: 0.5rem auto;
  }
}
