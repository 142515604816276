.drawer {
  z-index: 10500;
  position: fixed;
  top: 0;
  right: 0;
  width: 280px;
  height: 100%;
  max-height: 100%;
  overflow: visible;
  overflow-y: auto;
  background-color: #1e1a1a;
  border-left: 3px solid #2f2c2c; }
